/* For use in src/lib/core/theming/_palette.scss */
$custom-primary: (
        50 : #f6edef,
        100 : #e8d1d7,
        200 : #d9b3bc,
        300 : #ca95a1,
        400 : #be7e8c,
        500 : #b36778,
        600 : #ac5f70,
        700 : #a35465,
        800 : #9a4a5b,
        900 : #8b3948,
        A100 : #ffdae0,
        A200 : #ffa7b6,
        A400 : #ff748c,
        A700 : #ff5a78,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
$custom-accent: (
        50 : #f4fff2,
        100 : #e3ffdf,
        200 : #d0ffc9,
        300 : #bdffb3,
        400 : #afffa3,
        500 : #a1ff93,
        600 : #99ff8b,
        700 : #8fff80,
        800 : #85ff76,
        900 : #74ff64,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #efffed,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #000000,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

