
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'material-data/custom-colors' as custom-mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$talentmarket-app-primary: mat.define-palette(custom-mat.$custom-primary, 600);

// color option
$talentmarket-app-accent: mat.define-palette(custom-mat.$custom-accent, 600);

// The warn palette is optional (defaults to red).
$talentmarket-app-warn: mat.define-palette(mat.$red-palette, 500);


// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$talentmarket-app-theme: mat.define-light-theme((
  color: (
    primary: $talentmarket-app-primary,
    accent: $talentmarket-app-accent,
    warn: $talentmarket-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($talentmarket-app-theme);

@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/Metropolis-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/Metropolis-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/Metropolis-ExtraBold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('/assets/fonts/Metropolis-LightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

// COLORS
:root {
  /* Black color */
  --black-color: rgba(0, 0, 0, 1);
  /* Background light grey color */
  --background-light-grey-color: rgba(248, 248, 248, 1);
  /* Background dark grey color */
  --background-dark-grey-color: rgba(223, 223, 223, 1);
  /* Text dark grey color */
  --text-dark-grey-color: rgba(129, 133, 137, 1);
  /* White color */
  --white-color: rgba(255, 255, 255, 1);
  /* Primary color */
  --primary-red-color: rgba(179, 103, 120, 1);
  /* Accent color */
  --accent-green-color: rgba(161, 255, 147, 1);
  /* Accent easy color */
  --accent-easy-green-color: rgba(161, 255, 147, 0.4);
  /* Body font */
  --ff-primary: "Metropolis", sans-serif;
}


html, body { height: 100%; }
body {
  margin: 0;
  font-family: var(--ff-primary);
  background-color: var(--background-light-grey-color)
}

.hide-xs    { @media screen and (max-width: 599px) { display: none; } }  // Hide on mobile.
.hide-gt-xs { @media screen and (min-width: 600px) { display: none; } }  // Show only on mobile. Hide on desktop.
.hide-sm    { @media screen and (max-width: 959px) { display: none; } }  // Hide on mobile/tablet.
.hide-gt-sm { @media screen and (min-width: 960px) { display: none; } }  // Show only on mobile/tablet. Hide on desktop.

.cursor-pointer {
  cursor: pointer;
}
.width-full {
  width: 100%;
}
.max-container-width {
  max-width: 1500px;
}

.horizontal-line-divider {
  width: 100%;
  display: block;
  background: var(--primary-red-color);
  height: 1px;
  border: 0;
  margin: 0 0 1rem 0;
  padding: 0;
  opacity: 1;
}

.basic-black-button {
  display: block;
  background-color: var(--black-color);
  color: var(--white-color);
  border: none;
  border-radius: 2rem;
  min-width: 9.5rem;
  padding: 0 1rem;
  height: 3rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.5s ease-in-out;
}
.basic-black-button:hover {
  background-color: #333;
}
.basic-black-button-phone-link {
  display: flex;
  align-items: center;
  color: var(--white-color);
  text-decoration: none;
}
.cat-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black-color);
  color: var(--white-color);
  border: none;
  border-radius: 2rem;
  min-width: 9.5rem;
  padding: 0 1rem;
  height: 3rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.5s ease-in-out;
}
.cat-button:hover {
  background-color: #333;
}
.cat-button-text {

}
input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  margin: 0;
}
input[type='radio']:after,
input[type='checkbox']:after {
  content: '';
  display: inline-block;
  position: relative;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
}
input[type='radio']:after {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  position: relative;
  background-color: var(--white-color);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--black-color);
}
input[type='radio']:checked:after {
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  position: relative;
  background-color: var(--black-color);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid var(--white-color);
}
input[type='checkbox']:after {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  position: relative;
  background-color: var(--white-color);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--black-color);
}
input[type='checkbox']:checked:after {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  position: relative;
  background-color: var(--black-color);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid var(--white-color);
}
input[type='text'], input[type='email'], input[type='number'], select, textarea {
  border: 2px solid transparent;
  border-radius: 0.5rem;
  padding: 0.5rem;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease;
}
input[type='text']:focus,
input[type='text']:hover,
input[type='email']:focus,
input[type='email']:hover,
input[type='number']:focus,
input[type='number']:hover,
textarea:focus,
textarea:hover ,
select:focus,
select:hover{
  border-color: var(--black-color);
}
